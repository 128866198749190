<svelte:options tag="fds-icon"></svelte:options>
<script>
    import fdsHelper from "@fds-components/fds-helper"
    import {name as cname, version as componentversion} from '../package.json';
    let path = fdsHelper.get_href();
    import {onMount}from 'svelte'
    import { get_current_component } from "svelte/internal";
    import {createEventDispatcher, tick} from 'svelte'
    let host = get_current_component();
    const dispatch = createEventDispatcher()
    /**
     * @typedef {string} iconString
     */


    /**
     * The icon name with prefix seperated by space see description below
     * @type {iconString}
     */
    export let name = ""
    /**
     * The icon height/size
     * @type {string}
     */
    export let height = 12
    /**
     *
     * @type {boolean}
     */
    export let nocache = false;
    let cssadded = false;
    let svgElement;
    let file;
    let oldname = null;
    let iconpark = false;
    let cssel = "";
    let strokeanims = [];
    /**
     * method to transform icon svg see description below - deprecated
     * @param svg
     */
     let transformSrc = (svg) => svg

    $: if(nocache=="false") nocache=false; nocache = !!nocache;
    $: if (name && name !== oldname) {
        cssadded = false;
        cssel = "";
        oldname = name;
        init();
    }

    onMount(() => {
        if (!oldname && name) oldname = name;
        init();
    });

    let iconSet=""
    let viewbox=null

    function animatePath(pathElement,start,duration) {
        let len=pathElement.getTotalLength();
        let style=pathElement.style;
        style.strokeDasharray=style.strokeDashoffset=len
        style.animation="stroke_anim "+duration+" ease forwards "+start
    }


    function getAllParams(arr){
        cssel +=`.iconpark path{fill:none} .iconpark rect{fill:none} .iconpark ellipse{fill:none} `;
        arr.forEach((el,idx)=>{
            if(idx>1){
                if(el.includes("theme")){
                }else if (el.includes("fill:")){
                    // fill:path,1,#f0d086
                    el = el.replace("fill:","");
                    let cssparams = el.split(",");
                    cssel +=`.iconpark ${cssparams[0]}:nth-child(${cssparams[1]}) { fill:${cssparams[2]}}`;
                }else if (el.includes("stroke-color:")){
                    el = el.replace("stroke-color:","");
                    let cssparams = el.split(",");
                    cssel +=`.iconpark ${cssparams[0]}:nth-child(${cssparams[1]}) { stroke:${cssparams[2]}}`;
                }else if (el.includes("stroke-anim:")){
                    el = el.replace("stroke-anim:","");
                    let cssparams = el.split(",");
                    strokeanims.push({'index':cssparams[0],'from':cssparams[1],'to':cssparams[2]});
                // all other stuff go there
                } else{
                    cssel +=`.iconpark path {${el}}`
                }
            }
        })
        cssel +=` @keyframes stroke_anim {to {stroke-dashoffset: 0;}`
    }

    function init() {
        let arr = []
        if (!name) return
        const moduleDir =path
        name = name.replace("fa-", "")

        if (name.includes(" ")) {
            // remove double spaces
            name = name.replace(/ +(?= )/g,'');
            arr = name.split(" ")
        } else {
            arr[0] = "fa"
            arr[1] = name
        }

        iconSet=arr[0];
        if (arr[0] == 'ip'){
            getAllParams(arr);
            iconpark=true;
        }
        if (iconSet==="flags") viewbox='0 0 15 15'
        file = moduleDir + "svgs/" + arr[0] + "/" + arr[1] + ".svg"
        inline(file)
    }


    let svgAttrs = {}
    let svgContent

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version"){
            return new Promise(resolve => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type,cname);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;




    function exclude(obj, exclude) {
        Object.keys(obj)
            .filter((key) => exclude.includes(key))
            .forEach((key) => delete obj[key])
        return obj
    }

    function filterAttrs(attrs) {
        return Object.keys(attrs).reduce((result, key) => {
            if (
                attrs[key] !== false &&
                attrs[key] !== null &&
                attrs[key] !== undefined
            ) {
                result[key] = attrs[key]
            }
            return result
        }, {})
    }

    function addCss(){
        if (!cssel) return;
        if(cssadded) return;
        cssadded = true;
        // remove old style if exist
        if (host.shadowRoot.querySelectorAll(".styleel").length){
            host.shadowRoot.querySelectorAll(".styleel")[0].remove();
        };
        let stylepath = `<style> ${cssel}</style>`;
        let divNode = document.createElement("span");
        divNode.classList.add("styleel");
        divNode.classList.add('styleelcontainer');
        divNode.innerHTML = stylepath ;
        host.shadowRoot.appendChild(divNode);
        let pathList=host.shadowRoot.querySelectorAll("path");
        if (pathList && pathList.length && strokeanims.length){
            strokeanims.forEach((el)=>{
                if (pathList[el.index]){
                    animatePath(pathList[el.index],el.from,el.to);
                }
            });
        }
    }

    async function render(svg) {
        const parser = new DOMParser()
        const result = parser.parseFromString(svg, 'text/xml')
        let svgEl = result.querySelector('svg')
        if (svgEl) {
            // Apply transformation
            svgEl = transformSrc(svgEl)
            const attrs = svgEl.attributes
            for (let i = attrs.length - 1; i >= 0; i--) {
                svgAttrs[attrs[i].name] = attrs[i].value
            }
            if (iconSet)
            // copy inner html
            svgContent = svgEl.innerHTML
        }
        await tick();
        addCss();
    }

    function inline(src) {
        let cache = {}
        if (nocache === false) {
            if (window.cacheFDSIcons) {
                cache = window.cacheFDSIcons

            } else {
                cache = {}
                // console.log("try to read icons from local Storage ",src)
                let iconsJSON = localStorage.getItem('fds-icons')
                if (iconsJSON) cache = JSON.parse(iconsJSON)
                window.cacheFDSIcons = cache
            }
        }

        if (!cache) cache = {}
        // fill cache with new icon
        if (!cache[src]) {
            console.log("loading", src)

            fetch(src)
                .then(response => response.text())
                .then((svg) => {
                        cache[src] = svg
                        localStorage.setItem('fds-icons', JSON.stringify(cache))
                        window.cacheFDSIcons = cache
                        render(svg)
                    }
                )
        } else {
            //    console.log("render from cache",src)
            render(cache[src])
        }

    }

</script>
<svg class:iconpark={iconpark} style="height:{height}px;width:{height}px;fill:var(--color,black);"
     xmlns="http://www.w3.org/2000/svg" {viewbox}
     bind:this={svgElement}
     bind:innerHTML={svgContent}
     {...svgAttrs}
     {...exclude($$props, ['src', 'transformSrc', 'style'])}
     contenteditable="true"/>
